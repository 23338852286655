import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Interweave } from 'interweave';
import { eventsSelector, fetchOneEvents } from '../../api/events';
import Ourevents from '../../images/ourevents.jpg';
import PageHeader from "./../global/pageheader";

export default function EventsPage() {

  const dispatch = useDispatch()
  const { current_events, loading } = useSelector(eventsSelector)
  const [modalIsOpen, setModalOpen] = useState(false)
  const { id } = useParams()


  console.log({ current_events });


  useEffect(() => {
    dispatch(fetchOneEvents(id))
  }, [dispatch])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className='container'>
      <div className="mt-2">
        
      <div className="row text-center">
                <h1 className="col">{current_events?.title}</h1>
                </div>
                
                <div className="row text-center">
                <h3 className="col">{current_events?.sub_title}</h3>
                </div>

        <img className="img-fluid round" height={'400px'} src={current_events?.image} alt="" />

        {current_events?.event_images.length >= 1 ? <p className='my-2 fs-5'>Images</p> : " "}
        <div className="row">
          {
            current_events?.event_images?.map((item, i) => {
              return <div key={i} className="col-sm-6 col-md-4">
                <img key={i} src={item?.image} alt="" className="img-fluid rounded" />
                <p key={i} className="text-center" >{item?.caption}</p>
              </div>

            })
          }
        </div>

        <div className='row'>
          {current_events?.featured_images.length >= 1 ? <p className='fs-5'>Press Releases</p> : ""}
        </div>
        <div className='row'>
          {
            current_events?.featured_images?.map((item, i) => {
              return <div key={i} className="col-md-6 col-sm-12 mx-auto">
                <img key={i} src={item.image} alt="" className="img-fluid rounded" />
                <p className=' text-justify' key={i} >{item.caption}</p>
              </div>
            })
          }
        </div>
        <div className="row">
          <Interweave content={current_events?.description} />
        </div>
      </div>
    </div>
  )
}


import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOneBlog, fetchAllblog, blogSelector } from '../../api/blog'
import { useParams } from 'react-router-dom'
import { Interweave } from 'interweave';

export default function BlogPage() {

    const dispatch = useDispatch()
    const { current_blog, loading } = useSelector(blogSelector)
    const { id } = useParams()

    console.log("Prop ", id);
    useEffect(() => {
        dispatch(fetchOneBlog(id))
    }, [dispatch])

    return (
        <section className="container px-4 mx-auto " style={{ fontFamily: 'Lato' }} >
            <div className=''>
                <div className="row text-center">
                <h1 className="col">{current_blog?.title}</h1>
                </div>
                <div className="row text-center">
                <h3 className="col">{current_blog?.sub_title}</h3>
                </div>
        
                <img className="h-full w-full object-cover rounded-lg mt-5" src={current_blog?.image} alt="" />

                <div className="px-4 mt-10 text-gray-700" >
                    <Interweave content={current_blog?.description} />
                </div>
            </div>
        </section>
    )
}


import React from 'react'
import { createContactEnquiry } from '../api/contactenquiry'
import { useDispatch } from 'react-redux'

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams , useNavigate } from 'react-router-dom';
import catalog from "./../images/catalogue.pdf";


const publicUrl = process.env.PUBLIC_URL;
export default function CatalogueRequest() {
  const { contact } = useParams();

  const navigate = useNavigate();
  let source = contact

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (value) => {
    value.source = 'catalog-request';
    value.message = 'CatalogRequest: ' + value.message;
    value.crmsource = 'Website-Download-Catalogue';

    dispatch(createContactEnquiry(value, navigate));

   // Creating new object of PDF file
    const fileURL = '/catalogs/' + value.catalogName;
    // Setting various property values
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = value.catalogName;
    alink.click();

    navigate('/thankyou');
  }

  return (
    <div className="container-fluid">
      <div className="row text-center mb-5">
        <h1>Download Catalogue</h1>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 mb-5 mx-auto">
          <div className="col">
            <form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
              <div className="form-group mb-3">
                <input type="text" id="first_name" placeholder="Name*" {...register("name", { required: true })} className="form-control" />
                {errors.name?.type === 'required'}
              </div>
              <div className="form-group mb-3">
                <input type="email" id="email" placeholder="Email Address*" {...register('email', { required: true })} className="form-control" />
                {errors.email?.type === 'required'}
              </div>
              <div className="form-group mb-3">
                <input type="text" id="phone_number" placeholder="Phone Number*" {...register('phone', { required: true })} className="form-control" />
                {errors.phone_number?.type === 'required'}
              </div>
              <div className="form-group mb-3">
                <input type="text" id="country" placeholder="Country"  {...register('country', { required: true })} className="form-control" />
                {errors.country?.type === 'required'}
              </div>
              <div className="form-group mb-3">
                <input type="text" id="state" placeholder="State" {...register('state', { required: true })} className="form-control" />
              </div>
              <div className="form-group mb-3">
                <input type="text" id="city" placeholder="City"   {...register('city', { required: true })} className="form-control" />
              </div>
              <div className="form-group mb-3">
                <select className='form-control' {...register('catalogName')}>
                  <option value="catalogue.pdf" selected >Select Catalogue To Download</option>
                  <option value="color_sortex.pdf">Color Sortex</option>
                  <option value="Catalogue_Turnkey_Rice_Mill_Solutions.pdf">Turnkey Rice Mill Solutions</option>
                  <option value="Catalogue_Grain_Based_Distillery.pdf">Grain Processing Machinery</option>
                  <option value="Catalogue_Grain_Processing_Machinery.pdf">Grain Based Distillery</option>
                </select>
              </div>
              <div className="form-group mb-3">
                <textarea type="text" id="message" rows="3" placeholder="Message(Optional)" {...register('message', { required: false })} className="form-control"></textarea>
              </div>
              <div className="form-group mb-3 d-grid gap-2">
                <input className="btn btn-primary btn-lg form-control" value="Submit" type="submit" />
              </div>
            </form>
            {visible && <p className='display-5'>Thanks for your enquiry, We will get in touch with you.</p>}
          </div>
        </div>
      </div>
    </div>
  )
}


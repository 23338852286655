import React from 'react'

import BannerInvestors from "./../../images/pages/pageheaders/banner_investors.jpg";
import BannerInvestorsMobile from "./../../images/pages/pageheaders/banner_investors_m.jpg";
import PageHeader from "./../global/pageheader";
import BoardComposition from './boardComposition';


export default function Investors() {

    return (
        <div className="container-fluid">
            <PageHeader img={BannerInvestors} img_mobile={BannerInvestorsMobile} head="Investors"></PageHeader>

            <div className="container">

                
            <div className="card mt-5 mb-2 shadow" >
                    <div className="card-header">
                        Investors Presentation
                    </div>
                    <div className="card-body">
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/investors/ppt_investors_021224.pdf">Investors Presentation</a>
                            </div></div> 
                    </div>
                </div>

                <div className="card mt-5 mb-2 shadow" >
                    <div className="card-header">
                        IPO
                    </div>
                    <div className="card-body">
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/sonamachinerylimitedredherringdraft.pdf">Draft Red Herring Prospectus</a>
                            </div>
                        </div>
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/sonamachinerylimitedredherring.pdf">Red Herring Prospectus</a>
                            </div>
                        </div>
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/preissueadvt.pdf">Pre Issue Advertisement</a>
                            </div>
                        </div>
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/pricebandadvt.pdf">Price Band Advertisement</a>
                            </div>
                        </div>
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/abridged-prospectus.pdf">Abridged Prospectus</a>
                            </div>
                        </div>
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/prospectus_sona.pdf">Prospectus</a>
                            </div>
                        </div>
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/basisofallotment.pdf">Basis of Allotment</a>
                            </div>
                        </div>
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/investors/cfcs">CFO/CS</a>
                            </div>
                        </div>
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/investors/rta">RTA</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-5 mb-2 shadow" >
                    <div className="card-header">
                        Investors
                    </div>
                    <div className="card-body">
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/investors/board-composition">Board Composition</a>
                            </div></div>
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/investors/shareholding-pattern">Shareholding Pattern</a>
                            </div></div><div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/investors/investor-news">News</a>
                            </div></div>
                    </div>
                </div>

                <div className="card mt-5 mb-2 shadow" >
                    <div className="card-header">
                        Shareholder Information
                    </div>
                    <div className="card-body">
                        <div className="card">
                            <div className="card-header">Auditor</div>
                            <div className="card-body">
                                <pre>
                                    RK Jagetiya & Co.
                                    B-303, Eklavya CHSL, N.L. Complex, Dahisar East,
                                    Mumbai, Maharashtra - 400068
                                </pre>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-5 mb-2 shadow" >
                    <div className="card-header">
                        Policies
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/1.pdf">1. Archival Policy</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/2.pdf">2. Code of Conduct of Board & Senior Management</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/3.pdf">3. Criteria of Making Payments to Non-Executive Directors</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/4.pdf">4. Nomination and Remuneration Policy</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/5.pdf">5. Policy for Preservation of Documents</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/6.pdf">6. Policy on Code of Practices and Procedures for Fair Disclosure of UPSI</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/7.pdf">7. Policy on Diversity of Board of Directors</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/8.pdf">8. Policy for Policy for Determination of Materiality of Events or Information</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/9.pdf">9. Policy on Prevention of Sexual Harassment at Workplace</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/10.pdf">10. Policy on Identification of Group Companies, Identification of Material Outstanding Creditors And Identification of Material Outstanding Litigations</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/11.pdf">11. Policy on Materiality of Related Party Transactions</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/12.pdf">12. Policy for Determining Material Subsidiary(ies) - -</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/13.pdf">13. Terms & conditions of Appointment of Independent Directors</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/14.pdf">14. Whistle Blower & Vigil Mechanism Policy</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/15.pdf">15. Policy on Orderly Succession Planning for the Board of Directors and Senior Management</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/16.pdf">16. Familiarisation Programme for Independent Directors</a>
                        </div>
                    </div>
                    <div className="card m-2">
                        <div className="card-body">
                            <a href="/investors/policies/17.pdf">17. CSR</a>
                        </div>
                    </div>
                </div>
                <div className="card mt-5 mb-2 shadow" >
                    <div className="card-header">
                        Committees
                    </div>
                    <div className="card-body">
                        <div className="card mt-2 mb-2" >
                            <div className="card-body">
                                <a href="/investors/committees">Committees</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
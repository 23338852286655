import React from 'react'
import { createContactEnquiry } from '../api/contactenquiry'
import { useDispatch } from 'react-redux'

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

function InfoCardLine(props) {
  return (
    <div className="row content-center d-flext justify-content-center mb-2">
      <div className="col-1"><i className={"bi bi-" + props.icon}></i> </div>
      <div className="col-8">
        {props.text}
      </div>
    </div>
  )
}

function InfoCard() {
  return (
    <div className="fs-5">
      <InfoCardLine icon="envelope" text=" inquiry@sonamachinery.com"></InfoCardLine>

      <InfoCardLine></InfoCardLine>
      <InfoCardLine icon="telephone-forward-fill" text="Inquiries : (+91) 9599 00 2201"></InfoCardLine>
      <InfoCardLine icon="" text=" Service & Support : (+91) 9599 00 2208"></InfoCardLine>

      <InfoCardLine></InfoCardLine>
      <InfoCardLine icon="buildings" text="Manufacturing Facility & Corporate Office:"></InfoCardLine>
      <InfoCardLine icon="" text="Plot F-16 & C-1"></InfoCardLine>
      <InfoCardLine icon="" text="Sector: A-3 Tronica City, "></InfoCardLine>
      <InfoCardLine icon="" text="Industrial Area, Loni, Ghaziabad, Uttar Pradesh - 201102"></InfoCardLine>

      <InfoCardLine></InfoCardLine>
      <InfoCardLine icon="buildings" text="Registered Office: "></InfoCardLine>
      <InfoCardLine icon="" text="No. 701, 7th Floor, KLJ Tower Plot No. B-5,"></InfoCardLine>
      <InfoCardLine icon="" text="Netaji Subhash Place, Maurya Enclave"></InfoCardLine>
      <InfoCardLine icon="" text="Delhi, India - 110034"></InfoCardLine>

      <InfoCardLine></InfoCardLine>
      <InfoCardLine icon="clock" text="Mon-Sat: 9 am 7 pm"></InfoCardLine>
      <InfoCardLine icon="" text=" Sunday: Closed"></InfoCardLine>
    </div>
  )
}

export default function Contactone() {
  const { contact } = useParams();
  
  const navigate = useNavigate();
  let source = contact

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (value) => {
    value.source = contact;
    value.crmsource = 'Website-Contact-Us'
    dispatch(createContactEnquiry(value,navigate))
  }


  return (
    <div className="container-fluid">
      <div className="row text-center mb-2">
        <h1>Contact Us</h1>
      </div>
      <div className="row text-center mb-5">
        <div className="col display-6">
          Sona Machinery Ltd.
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md mb-5">
          <h4 className="text-center mb-5 pb-3 border-bottom">
            Get In touch with us
          </h4>
          <InfoCard></InfoCard>
        </div>
        <div className="col-sm-12 col-md mb-5">
          <div className="col">
            <h4 className="text-center mb-5 pb-3 border-bottom">
              Drop us a line
            </h4>
            <form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
              <div className="form-group mb-3">
                <input type="text" id="first_name" placeholder="Name*" {...register("name", { required: true })} className="form-control" />
                {errors.name?.type === 'required'}
              </div>
              <div className="form-group mb-3">
                <input type="email" id="email" placeholder="Email Address*" {...register('email', { required: true })} className="form-control" />
                {errors.email?.type === 'required'}
              </div>
              <div className="form-group mb-3">
                <input type="text" id="phone_number" placeholder="Phone Number*" {...register('phone', { required: true })} className="form-control" />
                {errors.phone_number?.type === 'required'}
              </div>
              <div className="form-group mb-3">
                <input type="text" id="country" placeholder="Country"  {...register('country', { required: true })} className="form-control" />
                {errors.country?.type === 'required'}
              </div>
              <div className="form-group mb-3">
                <input type="text" id="state" placeholder="State" {...register('state', { required: true })} className="form-control" />
              </div>
              <div className="form-group mb-3">
                <input type="text" id="city" placeholder="City"   {...register('city', { required: true })} className="form-control" />
              </div>
              <div className="form-group mb-3">
                <textarea type="text" id="message" rows="3" placeholder="Message(Optional)" {...register('message', { required: true })} className="form-control"></textarea>
              </div>
              <div className="form-group mb-3 d-grid gap-2">
                <input className="btn btn-primary btn-lg form-control" value="Submit" type="submit" />
              </div>
            </form>
            {visible && <p className='display-5'>Thanks for your enquiry, We will get in touch with you.</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

